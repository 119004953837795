// import React, { ReactElement } from 'react';
// import { useTranslation } from 'react-i18next';

// import SEO from '../seo';
// // import AsSeenIn from './components/as-seen-in';
// import Certifications from './components/certifications';
// import LandingTop from './components/landing-top';
// // import Testimonials from './components/testimonials';
// // import Faq from './components/faq';

// import './landing.css';

// function Landing(): ReactElement {
//   const { t } = useTranslation();

//   return (
//     <>
//       <SEO title={t('metaTags:title')} />
//       <main className='landing-page' style={{ backgroundColor: 'white' }}>
//         <LandingTop />
//         {/* <AsSeenIn /> */}
//         {/* <Testimonials /> */}
//         <Certifications />
//         {/* <Faq /> */}
//       </main>
//     </>
//   );
// }

// Landing.displayName = 'Landing';
// export default Landing;

import Login from "../Header/components/login";

import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";

import Certifications from "./components/certifications";
import LandingTop from "./components/landing-top";
import { Spacer } from "../helpers";
import Faq from "./components/faq";
import GetStartedWhite from '../Header/components/get-started-white';
import why from './why.gif'

import "./landing.css";

function Landing(): ReactElement {
  const { t } = useTranslation();

  // Set the meta title, description, and icon in the head
  React.useEffect(() => {
    // Set the document title
    document.title = "Web4Jobs CodeCamp - Learn to code with Web4Jobs";

    // Set meta description for general use
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute(
        "content",
        "Join Web4Jobs CodeCamp and learn to code with industry experts. Start your coding journey today!"
      );
    } else {
      const meta = document.createElement("meta");
      meta.name = "description";
      meta.content =
        "Join Web4Jobs CodeCamp and learn to code with industry experts. Start your coding journey today!";
      document.head.appendChild(meta);
    }

    // Set favicon for the website
    const link = document.querySelector('link[rel="icon"]');
    if (link) {
      link.setAttribute("href", "https://a.top4top.io/p_3048msg4q1.png");
    } else {
      const icon = document.createElement("link");
      icon.rel = "icon";
      icon.href = "https://a.top4top.io/p_3048msg4q1.png";
      document.head.appendChild(icon);
    }

    // Open Graph (OG) Tags for sharing on social media
    const ogTitle = document.querySelector('meta[property="og:title"]');
    if (!ogTitle) {
      const ogTitleTag = document.createElement("meta");
      ogTitleTag.setAttribute("property", "og:title");
      ogTitleTag.content = "Web4Jobs CodeCamp - Learn to code with Web4Jobs";
      document.head.appendChild(ogTitleTag);
    }

    const ogDescription = document.querySelector(
      'meta[property="og:description"]'
    );
    if (!ogDescription) {
      const ogDescriptionTag = document.createElement("meta");
      ogDescriptionTag.setAttribute("property", "og:description");
      ogDescriptionTag.content =
        "Join Web4Jobs CodeCamp and learn to code with industry experts. Start your coding journey today!";
      document.head.appendChild(ogDescriptionTag);
    }

    const ogImage = document.querySelector('meta[property="og:image"]');
    if (!ogImage) {
      const ogImageTag = document.createElement("meta");
      ogImageTag.setAttribute("property", "og:image");
      ogImageTag.content = "https://a.top4top.io/p_3048msg4q1.png"; // You can replace this with an actual image URL for the preview.
      document.head.appendChild(ogImageTag);
    }

    const ogUrl = document.querySelector('meta[property="og:url"]');
    if (!ogUrl) {
      const ogUrlTag = document.createElement("meta");
      ogUrlTag.setAttribute("property", "og:url");
      ogUrlTag.content = window.location.href; // Dynamically gets the current URL
      document.head.appendChild(ogUrlTag);
    }

    // Twitter Card Tags
    const twitterTitle = document.querySelector('meta[name="twitter:title"]');
    if (!twitterTitle) {
      const twitterTitleTag = document.createElement("meta");
      twitterTitleTag.setAttribute("name", "twitter:title");
      twitterTitleTag.content =
        "Web4Jobs CodeCamp - Learn to code with Web4Jobs";
      document.head.appendChild(twitterTitleTag);
    }

    const twitterDescription = document.querySelector(
      'meta[name="twitter:description"]'
    );
    if (!twitterDescription) {
      const twitterDescriptionTag = document.createElement("meta");
      twitterDescriptionTag.setAttribute("name", "twitter:description");
      twitterDescriptionTag.content =
        "Join Web4Jobs CodeCamp and learn to code with industry experts. Start your coding journey today!";
      document.head.appendChild(twitterDescriptionTag);
    }

    const twitterImage = document.querySelector('meta[name="twitter:image"]');
    if (!twitterImage) {
      const twitterImageTag = document.createElement("meta");
      twitterImageTag.setAttribute("name", "twitter:image");
      twitterImageTag.content = "https://a.top4top.io/p_3048msg4q1.png"; // Same image or a different image can be used
      document.head.appendChild(twitterImageTag);
    }

    const twitterCard = document.querySelector('meta[name="twitter:card"]');
    if (!twitterCard) {
      const twitterCardTag = document.createElement("meta");
      twitterCardTag.setAttribute("name", "twitter:card");
      twitterCardTag.content = "summary_large_image"; // Can be 'summary' for smaller cards
      document.head.appendChild(twitterCardTag);
    }
  }, []);

  return (
    <>
      <main className="landing-page" style={{ backgroundColor: "white" }}>
        <LandingTop />
        <Certifications />
        <section class="container mb-lg-8 custom-margin ">
          <main class="why-us d-flex flex-column flex-lg-row align-items-center">
            <div class="left col-lg-5 text-center mb-4 mb-lg-0">
              <img
                src={why}
                alt="Learning Animation"
                class="img-fluid rounded"
              />
            </div>
            <div class="right col-lg-7">
              <h5 class="why-us-text ">WHY CHOOSE US</h5>
              <h1 class="font-weight-bold mb-3 why-big-title">
                The Web4jobs Advantage in Programming and Certification
              </h1>
              <p className="why-paragraph"> 
                Web4jobs is your trusted partner in learning programming and
                achieving industry-recognized certifications. We offer a blend
                of expert-led courses, practical assessments, and a structured
                certification path to ensure you're equipped with the skills to
                excel in today's tech-driven world.
              </p>

              <div class="grid-choose">
                <div class="choose">
                  <svg
                    width="30px"
                    height="auto"
                    viewBox="-0.5 0 25 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                    <g
                      id="SVGRepo_tracerCarrier"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                      {" "}
                      <path
                        d="M12 22.4199C17.5228 22.4199 22 17.9428 22 12.4199C22 6.89707 17.5228 2.41992 12 2.41992C6.47715 2.41992 2 6.89707 2 12.4199C2 17.9428 6.47715 22.4199 12 22.4199Z"
                        stroke="#432B84"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>{" "}
                      <path
                        d="M10.5596 8.41992L13.6196 11.29C13.778 11.4326 13.9047 11.6068 13.9914 11.8015C14.0781 11.9962 14.123 12.2068 14.123 12.4199C14.123 12.633 14.0781 12.8439 13.9914 13.0386C13.9047 13.2332 13.778 13.4075 13.6196 13.55L10.5596 16.4199"
                        stroke="#432B84"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>{" "}
                    </g>
                  </svg>
                  <span>Online Certifications</span>
                </div>
                <div class="choose">
                  <svg
                    width="30px"
                    height="auto"
                    viewBox="-0.5 0 25 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                    <g
                      id="SVGRepo_tracerCarrier"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                      {" "}
                      <path
                        d="M12 22.4199C17.5228 22.4199 22 17.9428 22 12.4199C22 6.89707 17.5228 2.41992 12 2.41992C6.47715 2.41992 2 6.89707 2 12.4199C2 17.9428 6.47715 22.4199 12 22.4199Z"
                        stroke="#432B84"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>{" "}
                      <path
                        d="M10.5596 8.41992L13.6196 11.29C13.778 11.4326 13.9047 11.6068 13.9914 11.8015C14.0781 11.9962 14.123 12.2068 14.123 12.4199C14.123 12.633 14.0781 12.8439 13.9914 13.0386C13.9047 13.2332 13.778 13.4075 13.6196 13.55L10.5596 16.4199"
                        stroke="#432B84"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>{" "}
                    </g>
                  </svg>

                  <span>Professional Courses</span>
                </div>
                <div class="choose">
                  <svg
                    width="30px"
                    height="auto"
                    viewBox="-0.5 0 25 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                    <g
                      id="SVGRepo_tracerCarrier"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                      {" "}
                      <path
                        d="M12 22.4199C17.5228 22.4199 22 17.9428 22 12.4199C22 6.89707 17.5228 2.41992 12 2.41992C6.47715 2.41992 2 6.89707 2 12.4199C2 17.9428 6.47715 22.4199 12 22.4199Z"
                        stroke="#432B84"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>{" "}
                      <path
                        d="M10.5596 8.41992L13.6196 11.29C13.778 11.4326 13.9047 11.6068 13.9914 11.8015C14.0781 11.9962 14.123 12.2068 14.123 12.4199C14.123 12.633 14.0781 12.8439 13.9914 13.0386C13.9047 13.2332 13.778 13.4075 13.6196 13.55L10.5596 16.4199"
                        stroke="#432B84"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>{" "}
                    </g>
                  </svg>

                  <span>Trusted by Thousands</span>
                </div>
                <div class="choose">
                  <svg
                    width="30px"
                    height="auto"
                    viewBox="-0.5 0 25 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                    <g
                      id="SVGRepo_tracerCarrier"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                      {" "}
                      <path
                        d="M12 22.4199C17.5228 22.4199 22 17.9428 22 12.4199C22 6.89707 17.5228 2.41992 12 2.41992C6.47715 2.41992 2 6.89707 2 12.4199C2 17.9428 6.47715 22.4199 12 22.4199Z"
                        stroke="#432B84"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>{" "}
                      <path
                        d="M10.5596 8.41992L13.6196 11.29C13.778 11.4326 13.9047 11.6068 13.9914 11.8015C14.0781 11.9962 14.123 12.2068 14.123 12.4199C14.123 12.633 14.0781 12.8439 13.9914 13.0386C13.9047 13.2332 13.778 13.4075 13.6196 13.55L10.5596 16.4199"
                        stroke="#432B84"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>{" "}
                    </g>
                  </svg>

                  <span>Comprehensive Programming Courses</span>
                </div>
              </div>

              <a href="#about" class="btn btn-primary button mt-5">About Us</a>
            </div>
          </main>
        </section>

 <section className="container custom-margin">
 <section class="ready-section container  text-center py-5 ">
  <h1>Ready to Explore Our Certification Programs ?</h1>
  <p>Gain industry-recognized skills with our comprehensive courses and certifications designed to advance your career in tech.</p>
  <GetStartedWhite />
</section>
 </section>

        <style>
          {` 

 .ready-section {
    background: linear-gradient(90deg, rgba(2, 0, 36, 1) 0%, rgba(67, 43, 132, 1) 35%, rgba(67, 43, 132, 1) 100%);
    border-radius: 20px;
    color: #fff;
    margin: 0 auto;
    padding-inline: 2rem;
    padding-block:4rem;
  }

  .ready-section h1 {
    font-size: 42px;
    margin-bottom: 1rem;
    font-weight: bold;
    color:white;
  }

  .ready-section p {
    font-size:18px;
    margin-bottom: 1.5rem;
    line-height: 1.6;
    color:white;
  }

  .get-started-button {
    background-color: #ff5722;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;
  }

  .get-started-button:hover {
    background-color: #e64a19;
  }

.ready-section{
 background: rgb(2,0,36);
background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(67,43,132,1) 35%, rgba(67,43,132,1) 100%);
border-radius:20px;

}
.container{
margin-top:0px;
}
   .custom-margin {
  margin-bottom: 5rem; /* Adjust value as needed */
}
  .why-us-text{
  font-size: 18px;
    line-height: 1.2;
    color: #432B84;

  }
  .why-big-title{
  font-size: 28px;
    color: black;
  }
  .why-us .right h1 {
    font-size: 2rem;
    line-height: 1.2;
    color: #333;
  }

  .grid-choose {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
  }

  .why-paragraph{
   font-size:16px;
   color:#575757;
  }
  .choose {
    background:none;
    border:none;
    color: #333;
    display:flex;
    align-items:center;
    padding-block:8px;
    gap:5px;
  }


  @media (max-width: 768px) {
  .container{
margin-top:14px;
}

    .grid-choose {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 15px;
  }
   .choose span{
    font-size:12px;
   }
     .choose {
    background:none;
    border:none;
    color: #333;
    display:flex;
    align-items:center;
    padding-block:0px;
    gap:5px;
  }
    .why-us {
      text-align: center;
    }
       .why-paragraph{
   font-size:12px;
   color:#575757;
  }
    .why-us .right h1 {
      font-size: 24px;
    }
         .custom-margin {
  margin-bottom: 14px; /* Adjust value as needed */
}
    
     .why-us-text{
  font-size: 12px;
    line-height: 1.2;
    color: #432B84;
  }
     .why-big-title{
  font-size: 18px;
    color: black;
  }
  .button{
   width:100%;
  }

  .ready-section h1 {
    font-size: 24px;
    margin-bottom: 1rem;
    font-weight: bold;
    color:white;
  }
   .ready-section p {
    font-size: 12px;
    margin-bottom: 1rem;
    color:white;
  }
  .why-us {
      grid-template-columns: 1fr 1fr;
      align-items: center;
    }
  }`}
        </style>
      </main>
    </>
  );
}

Landing.displayName = "Landing";
export default Landing;
