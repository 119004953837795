import React from "react";
import { useTranslation } from "react-i18next";
import {
  SuperBlockStages,
  SuperBlocks,
  superBlockOrder,
} from "../../../../shared/config/superblocks";
import { SuperBlockIcon } from "../../assets/icons/superblock-icon";
import LinkButton from "../../assets/icons/link-button";
import { Link, Spacer } from "../helpers";
import { getSuperBlockTitleForMap } from "../../utils/superblock-map-titles";
import { getSuperBlockIntroForMap } from "../../utils/superblock-map-intro";
import { showUpcomingChanges } from "../../../config/env.json";
import "./map.css";
import Login from "../Header/components/login"; // Import the login component
import LoginToSee from "../Header/components/logintosee"; // This is likely the wrong path
import { useSelector, useDispatch } from "react-redux"; // Add useSelector and useDispatch to access Redux state

import { isSignedInSelector } from "../../redux/selectors";

interface MapProps {
  forLanding?: boolean;
}

const linkSpacingStyle = {
  display: "flex",
  flexDirection: "column" as "column",
  alignItems: "center",
};

const coreCurriculum = [
  ...superBlockOrder[SuperBlockStages.FrontEnd],
  ...superBlockOrder[SuperBlockStages.Backend],
  ...superBlockOrder[SuperBlockStages.Python],
];

function MapLi({
  superBlock,
  landing = false,
}: {
  superBlock: SuperBlocks;
  landing: boolean;
}) {
  const isSignedIn = useSelector(isSignedInSelector); // Get sign-in status from Redux

  // Get the title for the superBlock
  const superBlockTitle = getSuperBlockTitleForMap(superBlock);

  // Check if the title matches "A2 English for Developers (Beta) Certification"
  const isA2English =
    superBlockTitle === "A2 English for Developers Certification";

  // const GoToTheLogin = ()=>{
  //   <Login />
  // }

  return (
    <li
      data-test-label="curriculum-map-button"
      className={`${isA2English ? "red-background" : "card"}`}
    >
      {isSignedIn ? (
        <Link className="card-link" to={`/learn/${superBlock}/`}>
          <div
            className={`${
              isA2English ? "card-container-red" : "card-container"
            }`}
          >
            <SuperBlockIcon className="map-icon" superBlock={superBlock} />
            <div className="card-body">
              <div className="card-title">{superBlockTitle}</div>
              <p className="card-text">
                {getSuperBlockIntroForMap(superBlock)}
              </p>
              <button
                className={`${
                  isA2English ? "details-button-red" : "details-button"
                }`}
              >
                See More Details
              </button>
            </div>
          </div>
        </Link>
      ) : (
        <div
          className={`${isA2English ? "card-container-red" : "card-container"}`}
        >
          <SuperBlockIcon className="map-icon" superBlock={superBlock} />
          <div className="card-body">
            <div
              className={`card-title ${isA2English ? "red-background" : ""}`}
            >
              {superBlockTitle}
            </div>
            <p className="card-text">{getSuperBlockIntroForMap(superBlock)}</p>
            {/* Show login button which opens the Login modal or redirect to login page */}
            <button
              className={`${
                isA2English ? "details-button-red" : "details-button"
              }`}
            >
              <LoginToSee />
            </button>
          </div>
        </div>
      )}
    </li>
  );
}

function Map({ forLanding = false }: MapProps): React.ReactElement {
  const { t } = useTranslation();

  return (
    <div className="map-ui " data-test-label="curriculum-map">
      {/* <h2 id="title-one" className='content-title-cards'  >{t('landing.core-certs-heading')}</h2>
      <ul>
        {coreCurriculum.map((superBlock, i) => (
          <MapLi key={i} superBlock={superBlock} landing={forLanding} />
        ))}
      </ul> */}
      {/* 
      <Spacer size='medium' /> */}


      <Spacer size="medium" />
      <Spacer size="medium" />
      <div className="english-comp">
        <h2 id="title-tow" className="content-title-cards">
          {t("landing.learn-english-heading")}
        </h2>
        <ul
          className={`${
            superBlockOrder[SuperBlockStages.English].some(
              (superBlock) =>
                getSuperBlockTitleForMap(superBlock) ===
                "A2 English for Developers Certification"
            )
              ? "english-ul"
              : ""
          }`}
        >
          {superBlockOrder[SuperBlockStages.English].map((superBlock, i) => (
            <MapLi key={i} superBlock={superBlock} landing={forLanding} />
          ))}
        </ul>
      </div>
      <Spacer size="medium" />
      <Spacer size="medium" />
    

      <div className="english-comp">
        <Spacer size="medium" />
        {/* <ul className={`${superBlockOrder[SuperBlockStages.English].some(superBlock => getSuperBlockTitleForMap(superBlock) === 'A2 English for Developers (Beta) Certification') ? 'english-ul' : ''}`}>
        {superBlockOrder[SuperBlockStages.English].map((superBlock, i) => (
          <MapLi key={i} superBlock={superBlock} landing={forLanding} />
        ))}
      </ul> */}

        <div className="new-solution-hero">
          <div className="left">
            <img
              src="https://www.syncfusion.com/blogs/wp-content/uploads/2020/07/Top-6-Front-End-Web-Development-Tools-to-Increase-Your-Productivity-in-2020-1.jpg"
              alt=""
            />
          </div>
          <div className="right">
            <h2 id="title-tow" className="content-title-cards">
              Web Developer & Solution Certification
            </h2>
            <p>
              Explore our comprehensive training program to become a certified
              web developer. <br />
              <br />
              Master the essential skills of Responsive Web Design to create
              adaptive, optimized websites for all devices. <br />
              <br />
              Dive into Front End Development Libraries to refine your
              interactive and dynamic user interfaces.
              <br />
              <br />
              Finally, learn to manage and build robust web services with our
              module on Back End Development and APIs, enabling you to create
              high-performance, secure applications.
            </p>
            <Spacer size="medium" />

            <a href="/certification-developpeur-web-and-solutions">
              See More Details{" "}
            </a>
          </div>
        </div>
      </div>
      <Spacer size="medium" />
      <Spacer size="medium" />


      <div className="english-comp">
        {/* <ul className={`${superBlockOrder[SuperBlockStages.English].some(superBlock => getSuperBlockTitleForMap(superBlock) === 'A2 English for Developers (Beta) Certification') ? 'english-ul' : ''}`}>
        {superBlockOrder[SuperBlockStages.English].map((superBlock, i) => (
          <MapLi key={i} superBlock={superBlock} landing={forLanding} />
        ))}
      </ul> */}

        <div className="new-solution-hero">
          <div className="right">
            <h2 id="title-tow" className="content-title-cards">
              Data Developer Certification
            </h2>
            <p>
              Explore our in-depth training program to become a certified data
              developer. <br />
              <br />
              Gain expertise in Data Visualization to effectively present and
              communicate insights through engaging visual representations.{" "}
              <br />
              <br />
              Master Relational Databases to organize and manage structured data
              efficiently. <br />
              <br />
              Finally, enhance your skills with Data Analysis using Python,
              allowing you to process and analyze large datasets with precision
              and accuracy.
            </p>
            <Spacer size="medium" />
            <a href="/certification-dveloppeur-data">See More Details </a>
          </div>
          <div className="left">
            <img
              src="https://bairesdev.mo.cloudinary.net/blog/2023/09/Hire-Big-Data-Developers.jpg"
              alt=""
            />
          </div>
        </div>
      </div>
      <Spacer size="medium" />
      <Spacer size="medium" />


      <div className="english-comp">
        {/* <ul className={`${superBlockOrder[SuperBlockStages.English].some(superBlock => getSuperBlockTitleForMap(superBlock) === 'A2 English for Developers (Beta) Certification') ? 'english-ul' : ''}`}>
        {superBlockOrder[SuperBlockStages.English].map((superBlock, i) => (
          <MapLi key={i} superBlock={superBlock} landing={forLanding} />
        ))}
      </ul> */}

        <div className="new-solution-hero">
          <div className="left">
            <img
              src="https://stepwise.pl/wp-content/uploads/2021/03/Software-development-cybersecurity-www.jpg"
              alt=""
            />
          </div>
          <div className="right">
            <h2 id="title-tow" className="content-title-cards">
              Security Developer Certification
            </h2>

            <p>
              Join our comprehensive training program to become a certified
              security developer. <br />
              <br />
              Learn to safeguard web applications with Information Security
              using HelmetJS, ensuring robust protection against common
              vulnerabilities. <br /> <br />
              Develop your expertise in Python for Penetration Testing,
              equipping you with the skills to identify and address potential
              security threats through hands-on testing and analysis. <br />{" "}
              <br />
              Finally, consolidate your knowledge to become a key player in
              securing and fortifying digital environments against cyber
              threats.
            </p>
            <Spacer size="medium" />
            <a href="/certification-developpeur-securite">See More Details </a>
          </div>
        </div>
      </div>



    <style>
       {
        `
        @media (max-width: 768px) {
         a{
          font-size:14px;
         }
        }
        `
       }
    </style>
      

      {showUpcomingChanges && (
        <>
          <h2 className="content-title-cards">
            {t("landing.upcoming-heading")}
          </h2>
          <ul>
            {superBlockOrder[SuperBlockStages.Upcoming].map((superBlock, i) => (
              <MapLi key={i} superBlock={superBlock} landing={forLanding} />
            ))}
          </ul>
        </>
      )}
    </div>
  );
}

Map.displayName = "Map";

export default Map;

// import React, { useState } from 'react';
// import { useTranslation } from 'react-i18next';
// import { SuperBlockStages, SuperBlocks, superBlockOrder } from '../../../../shared/config/superblocks';
// import { SuperBlockIcon } from '../../assets/icons/superblock-icon';
// import { Link, Spacer } from '../helpers';
// import { getSuperBlockTitleForMap } from '../../utils/superblock-map-titles';
// import { getSuperBlockIntroForMap } from '../../utils/superblock-map-intro';
// import { showUpcomingChanges } from '../../../config/env.json';
// import './map.css';
// import { useSelector } from 'react-redux';
// import { isSignedInSelector } from '../../redux/selectors';

// interface MapProps {
//   forLanding?: boolean;
// }

// // Modal Component
// const Modal = ({ show, handleClose }: { show: boolean; handleClose: () => void }) => {
//   return (
//     <div className={`modal ${show ? 'display-block' : 'display-none'}`} style={ show ? { display: 'block' } : { display: 'none' }}>
//       <div className="modal-content">
//         <span className="close" onClick={handleClose}>&times;</span>
//         <p>Please log in to see details.</p>
//       </div>
//     </div>
//   );
// };

// const coreCurriculum = [
//   ...superBlockOrder[SuperBlockStages.FrontEnd],
//   ...superBlockOrder[SuperBlockStages.Backend],
//   ...superBlockOrder[SuperBlockStages.Python],
// ];

// function MapLi({ superBlock, landing = false }: { superBlock: SuperBlocks; landing: boolean }) {
//   const isSignedIn = useSelector(isSignedInSelector);  // Get sign-in status from Redux
//   const [showModal, setShowModal] = useState(false);   // State to control the modal

//   // Get the title for the superBlock
//   const superBlockTitle = getSuperBlockTitleForMap(superBlock);

//   // Check if the title matches "A2 English for Developers (Beta) Certification"
//   const isA2English = superBlockTitle === 'A2 English for Developers (Beta) Certification';

//   // Open Modal handler
//   const openModal = () => {
//     console.log('Opening Modal...');
//     setShowModal(true);
//   };

//   // Close Modal handler
//   const closeModal = () => {
//     console.log('Closing Modal...');
//     setShowModal(false);
//   };

//   return (
//     <>
//       <li data-test-label='curriculum-map-button' className={`${isA2English ? 'red-background' : 'card'}`}>
//         {isSignedIn ? (
//           <Link className='card-link' to={`/learn/${superBlock}/`}>
//             <div className={`${isA2English ? 'card-container-red' : 'card-container'}`}>
//               <SuperBlockIcon className='map-icon' superBlock={superBlock} />
//               <div className="card-body">
//                 <div className="card-title">
//                   {superBlockTitle}
//                 </div>
//                 <p className="card-text">{getSuperBlockIntroForMap(superBlock)}</p>
//                 <button className={`${isA2English ? 'details-button-red' : 'details-button'}`}>See More Details</button>
//               </div>
//             </div>
//           </Link>
//         ) : (
//           <div className={`${isA2English ? 'card-container-red' : 'card-container'}`}>
//             <SuperBlockIcon className='map-icon' superBlock={superBlock} />
//             <div className="card-body">
//               <div className={`card-title ${isA2English ? 'red-background' : ''}`}>
//                 {superBlockTitle}
//               </div>
//               <p className="card-text">{getSuperBlockIntroForMap(superBlock)}</p>
//               <button className={`${isA2English ? 'details-button-red' : 'details-button'}`} onClick={openModal}>See More Details</button>
//             </div>
//           </div>
//         )}
//       </li>

//       {/* Render the modal if not signed in */}
//       <Modal show={showModal} handleClose={closeModal} />
//     </>
//   );
// }

// function Map({ forLanding = false }: MapProps): React.ReactElement {
//   const { t } = useTranslation();

//   return (
//     <div className='map-ui' data-test-label='curriculum-map'>
//       <h2 id="title-one" className='content-title-cards'>{t('landing.core-certs-heading')}</h2>
//       <ul>
//         {coreCurriculum.map((superBlock, i) => (
//           <MapLi key={i} superBlock={superBlock} landing={forLanding} />
//         ))}
//       </ul>

//       <Spacer size='medium' />

//       <div className="english-comp">
//         <h2 id='title-tow' className='content-title-cards'>{t('landing.learn-english-heading')}</h2>
//         <ul className={`${superBlockOrder[SuperBlockStages.English].some(superBlock => getSuperBlockTitleForMap(superBlock) === 'A2 English for Developers (Beta) Certification') ? 'english-ul' : ''}`}>
//           {superBlockOrder[SuperBlockStages.English].map((superBlock, i) => (
//             <MapLi key={i} superBlock={superBlock} landing={forLanding} />
//           ))}
//         </ul>
//       </div>

//       {showUpcomingChanges && (
//         <>
//           <Spacer size='medium' />
//           <h2 className='content-title-cards'>{t('landing.upcoming-heading')}</h2>
//           <ul>
//             {superBlockOrder[SuperBlockStages.Upcoming].map((superBlock, i) => (
//               <MapLi key={i} superBlock={superBlock} landing={forLanding} />
//             ))}
//           </ul>
//         </>
//       )}
//     </div>
//   );
// }

// Map.displayName = 'Map';

// export default Map;
