import React from "react";
import { useTranslation } from "react-i18next";
import GetStarted from "../../Header/components/get-started";
import { clientLocale } from "../../../../config/env.json";
import { Spacer } from "../../helpers";
import hero from "./herogif.gif";

function LandingTop(): JSX.Element {
  const { t } = useTranslation();
  const showChineseLogos = ["chinese", "chinese-tradition"].includes(clientLocale);

  return (
    <>
      <section className="container">
        <div className="hero-section ">
          <div className="hero-content">
            <h1>Get Verified Certifications with <strong className="special-title">Web<span className="for">4</span>Jobs</strong> Today</h1>
            <p className="hero-descript">
              Unlock your potential with, verified certifications. Enhance your skills and career prospects with industry-recognized credentials from Web4Jobs.
            </p>
            <GetStarted />
          </div>
          <div className="hero-image">
            <img
              src={hero}
              alt="Web Development Illustration"
              loading="lazy"
              className="hero-thumbnail"
            />
          </div>
        </div>
      </section>

      <Spacer size="medium" />

      <section className="container" id="about">
        <div className="hero-section">
          <div className="hero-content">
            <h1 className="hero-title">What is <strong className="special-title">Web<span className="for">4</span>Jobs</strong>?</h1>
            <p className="hero-description">
              Web4Jobs is a Moroccan company committed to enhancing the employability of young people by focusing on retraining in the rapidly evolving digital field. Our mission is to empower individuals with the essential skills and knowledge required to thrive in today's job market.
              <br /><br />
              We offer a range of programs designed to strengthen professional competencies, ensuring that participants are well-equipped to meet the demands of various industries.
              <br /><br />
              Through hands-on training, mentorship, and partnerships with leading organizations, Web4Jobs strives to create meaningful opportunities for youth, fostering a generation of skilled professionals ready to contribute to the digital economy.
            </p>
            <h5 className="hero-tagline">
              Web4Jobs - Dare to go digital, chart your path to the professional future!
            </h5>
          </div>
          <div className="hero-image">
            <img
              src="https://img.freepik.com/premium-photo/computer-screen-with-words-code-it_1026278-22997.jpg"
              alt="Illustration of a full-stack developer"
              className="hero-thumbnail"
            />
          </div>
        </div>
      </section>

      <style jsx>{`

@import url('https://fonts.googleapis.com/css2?family=Bangers&family=Caveat:wght@400..700&family=Dela+Gothic+One&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Lexend:wght@100..900&family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap');        .container {
          padding-inline: 20px;
        }
        .special-title{
        color:#432b84;
        }
        .special-title .for{
        color:#432b84;
        }
        .hero-section {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin: 0 auto;
          min-height: 55vh;
        }
        .hero-descript{
         margin-bottom:60px;
         font-size:16px;
         color:black;
        }
        .hero-content {
          max-width: 700px;
          padding: 10px;
        }
        .hero-content h1, .hero-title {
          font-family: 'Inter', sans-serif;
          font-weight: 800;
          color: #333;
        }
        h1 {
          font-size: 46px;
          line-height: 58px;
          margin-bottom: 1rem;
        }
        .hero-description {
          font-size: 16px;
          line-height: 1.5;
          color: #555;
          margin-bottom: 2rem;
        }
        .hero-tagline {
          font-size: 16px;
          font-weight: bold;
          color: #797979;
          margin-top: 1rem;
        }
        .hero-image {
          max-width: 100%;
          height: auto;
          border-radius: 10px;
          margin-top: 16px;
        }
        .hero-thumbnail {
          max-width: 100%;
          border-radius: 8px;
        }
        
        /* Laptop screens */
        @media (max-width: 1024px) {
          .hero-section {
            flex-direction: column;
            text-align: center;
          }
          h1, .hero-title {
            font-size: 32px;
          }
          .hero-description {
            font-size: 14px;
          }
          .hero-tagline {
            font-size: 16px;
          }
        }
        /* Tablet screens */
        @media (max-width: 768px) {
          .container {
            padding-inline: 10px;
          }
          h1, .hero-title {
            font-size: 28px;
            line-height: normal;
          }
          .hero-description {
            font-size: 14px;
            margin-bottom: 14px;
          }
          .hero-descript{
         margin-bottom:20px;
         font-size:12px;
         color:black;
        }
        }
        /* Mobile screens */
        @media (max-width: 480px) {
          h1, .hero-title {
            font-size: 24px;
          }
          .hero-description {
            font-size: 13px;
          }
          .hero-tagline {
            font-size: 14px;
            margin-top: 12px;
          }
        }
      `}</style>
    </>
  );
}

LandingTop.displayName = "LandingTop";
export default LandingTop;
